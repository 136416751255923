<template>
  <div id="arc" ref="chartContainer"></div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "Arc",
  props: {
    countries: {
      type: Array,
    },
  },
  data() {
    return {
      ac: [
        { country: "USA", value: 20.5 },
        { country: "China", value: 13.4 },
        { country: "Germany", value: 4.0 },
        { country: "Japan", value: 4.9 },
        { country: "France", value: 2.8 },
      ],
    };
  },
  methods: {
    generateArc: function () {
      //console.log("generating arc");
      const w = 300;
      const h = 300;

      const svg = d3
        .select("#arc")
        //.select("this.$refs.chartContainer")
        .append("svg")
        .attr("width", w)
        .attr("height", h)
        .attr("opacity", 1);

      const sortedAC = this.countries.sort((a, b) =>
        a.value > b.value ? 1 : -1
      );
      const color = d3.scaleOrdinal(d3.schemeSet3);

      const max_ac = d3.max(sortedAC, (o) => o.value);

      const angleScale = d3
        .scaleLinear()
        .domain([0, max_ac])
        .range([0, 1.5 * Math.PI]);

      const arc = d3
        .arc()
        .innerRadius((d, i) => (i + 1) * 25)
        .outerRadius((d, i) => (i + 2) * 25)
        .startAngle(angleScale(0))
        .endAngle((d) => angleScale(d.value));

      const g = svg.append("g");

      g.selectAll("path")
        .data(sortedAC)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", (d, i) => color(i))
        .attr("stroke", "#475059")
        .attr("stroke-width", "1px")

        .on("mouseenter", function () {
          d3.select(this).transition().duration(200).attr("opacity", 0.5);
        })
        .on("mouseout", function () {
          d3.select(this).transition().duration(200).attr("opacity", 1);
        });

      g.selectAll("text")
        .data(this.countries)
        .enter()
        .append("text")
        .text((d) => `${d.country} -  $${d.value}`)
        .attr("x", -150)
        .attr("dy", -8)
        .attr("y", (d, i) => -(i + 1) * 25)
        .style("fill", "#fff");

      //g.attr("transform", "translate(200,300)");
      g.attr("transform", "translate(150,150)");
    },
    /*
    fade: function(){
              .transition()
        .duration(200)
        .attr("opacity", 1);
    }
    */
  },
  computed: {},

  mounted() {
    this.generateArc();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
